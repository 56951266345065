.grid-container {
  display: flex;
  flex-wrap: wrap;
  margin-top: 20px;
  justify-content: space-between;
  align-items: flex-start;
}

.grid-container.odd {
}

.grid-item.odd:last-child {
  width: 100%;
}

.grid-item {
  width: 48%;
  height: auto;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}
