.react-time-input-picker {
  color: #212121;
  width: max-content;
  border-radius: 2px;
  padding: 0;
  font-size: 16px;
  font-weight: 400;
  display: flex;
  margin: 0;
  align-items: center;
  height: 100%;
  width: auto;
  border: 1px solid #dadada;
  justify-content: center;
  position: relative;
}

.react-time-input-picker input::-webkit-outer-spin-button,
.react-time-input-picker input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.react-time-input-picker input[type='number'] {
  -moz-appearance: textfield;
}

.react-time-input-picker input {
  position: relative;
  margin: 0 2px;
  padding: 0;
  border-radius: 2px;
  background: transparent;
  width: max-content;
  min-width: 38px;
  text-align: center;
  border: none;
  outline: none;
  display: flex;
  justify-content: center;
  height: 100%;
  align-items: center;
}

.react-time-input-picker input::selection {
  background: transparent;
}

.react-time-input-picker input::placeholder {
  opacity: 1;
  font-weight: inherit;
  font-size: 18px;
}

.inputWrapper {
  position: relative;
  align-items: center;
  height: 100%;
  max-width: auto;
  display: flex;
}

.react-time-input-picker.is-disabled,
.react-time-input-picker.is-disabled * {
  cursor: not-allowed !important;
}

.inputWrapper:nth-child(2) {
  max-width: auto;
}

.inputWrapper:nth-child(1)::after {
  content: ':';
  display: flex;
  align-items: center;
  font-size: inherit;
  font-weight: inherit;
  margin-bottom: 0;
  height: 100%;
  box-sizing: border-box;
  position: relative;
  top: -1px;
}

.react-time-input-picker input:focus {
  caret-color: transparent;
  background-color: #dadada;
}

.react-time-input-picker span {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  font-weight: inherit;
  margin-bottom: 3px;
}

.react-time-input-picker input[type='text'] {
  min-width: unset;
  max-width: 45px;
  height: 100%;
}

.react-time-input-picker .arrow-down {
  margin-right: 7px;
  cursor: pointer;
  height: 100%;
  margin-bottom: 0;
}

.react-time-input-picker .input-time-mobile input[type='time'] {
  background: transparent;
}
.react-time-input-picker-wrapper {
  height: 37px;
  margin: 5px 0;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  width: max-content;
  justify-content: center;
}

.react-time-input-picker-wrapper * {
  box-sizing: border-box;
}
.inputWrapper__dropdown {
  position: absolute;
  left: 0;
  bottom: -205px;
  border-radius: 5px;
  height: 200px;
  overflow: auto;
  min-width: 150%;
  width: 150%;
  border: 1px solid #eeedee;
  box-shadow: 0 0 6px 0.2px #a0a0a0;
  display: flex;
  background-color: white;
  flex-direction: column;
  z-index: 999;
  padding: 5px 0;
}

.manuallyDisplayDropdown .inputWrapper__dropdown {
  min-width: 110%;
  width: 110%;
}

.inputWrapper__dropdown.amPm {
  width: 110%;
  min-width: 110%;
  bottom: -81px;
  overflow-y: hidden;
  height: 76px;
}

.inputWrapper__dropdown span {
  min-height: 30px;
  color: #434343;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-left: 10px;
  font-size: 14px;
  cursor: pointer;
  padding: 0 10px;
  font-weight: 500;
  margin-bottom: 0;
  margin-bottom: 2px;
}
.inputWrapper__dropdown span.is-active,
.inputWrapper__dropdown span:hover {
  background: #dadada;
}

.inputWrapper__dropdown::-webkit-scrollbar {
  width: 8px;
  height: 6px;
}

.inputWrapper__dropdown::-webkit-scrollbar-track {
  background-color: #eeedee;
  border-radius: 4px;
}

.inputWrapper__dropdown::-webkit-scrollbar-thumb {
  background: #a0a0a0;
  border-radius: 4px;
}

.inputWrapper__dropdown::-webkit-scrollbar-thumb:hover {
  background: #434343;
}

.fullTime__wrapper {
  position: absolute;
  width: 110%;
  left: -5%;
  right: 5%;
  bottom: 0px;
}

.fullTime__wrapper .inputWrapper__dropdown.fullTime {
  left: 0;
  right: 0;
  width: 100%;
  min-width: 100%;
}

.fullTime__wrapper .inputWrapper__dropdown.fullTime span {
  font-weight: 500;
  font-size: 16px;
}

.fullTime__wrapper .inputWrapper__dropdown.fullTime div {
  height: 100%;
  display: inline-flex;
  font-weight: 500;
  font-size: 16px;
  margin-bottom: 2px;
  margin-left: 10px;
  align-items: center;
}
